@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200..1000;1,200..1000&display=swap');

body {
  margin: 0;
  font-family: 'Nunito';
}

h1, h2, h3, h4, h5, h6, p {
  margin: 0
}

h2 {
  font-size: 24px;
  font-weight: 900;
}

.separator {
  width: 20px;
  height: 4px;
  border-radius: 2px;
  background-color: rgba(180, 180, 192, 1);;
}

.section {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.container {
  width: 100%;
  box-sizing: border-box;
  padding: 24px;
}

.primary-btn {
  font-size: 16px;
  height: 48px;
  background-color: white;
  display: flex;
  align-items: center;
  padding: 0 24px;
  border-radius: 28px;
  color: rgba(20, 20, 20, 1);
  font-weight: 800;
  cursor: pointer;
}

.secondary-btn {
  font-size: 16px;
  box-sizing: border-box;
  height: 48px;
  display: flex;
  align-items: center;
  padding: 0 24px;
  border-radius: 28px;
  border: 2px solid white;
  background: rgba(20, 20, 20, 0.6);
  font-weight: 800;
  backdrop-filter: blur(20px);
  cursor: pointer;
}

@media only screen and (min-width: 768px) {
  .container {
    width: 768px;
  }

  h2 {
    font-size: 24px;
  }
}

@media only screen and (min-width: 1152px) {
  .container {
    width: 1152px;
  }

  .primary-btn, .secondary-btn {
    height: 56px;
    font-size: 18px;
  }

  h2 {
    font-size: 32px;
  }
}

.start-top {
  width: 100%;
  background-position: center; /* Center the image */
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
  color: white;
}

.start-top-container {
  display: flex;
  flex-direction: column;
}

.start-top-cells {
  display: flex;
  flex-direction: column;
}

.start-top-header {
  width: 100%;
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 800;
  z-index: 2;
}

.start-top-header span {
  color: rgba(102, 102, 115, 1);
  margin-right: 6px;
}

.start-top-header img {
  width: 48px;
  height: 48px;
  margin-right: 8px;
}

.start-top-left {
  width: 100%;
  float: left;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 1;
  gap: 32px;
  margin: 40px 0 64px 0;
}

.start-top-right {
  width: 100%;
  float: left;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 32px;
  z-index: 0;
  justify-content: center;
}

.top-heading {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
}

.top-heading-cell {
  background: rgba(20, 20, 20, 0.6);
  font-size: 44px;
  padding: 8px 24px;
  border-radius: 16px;
  backdrop-filter: blur(20px);
  font-weight: 1000;
}

.top-heading-cell-bottom {
  transform: translateY(0px);
}

.top-buttons {
  display: flex;
  gap: 20px;
}

.start-top-right img {
  width: 80%;
  object-fit: contain;
  animation-name: slidein;
  animation-duration: 4s;
}

@keyframes slidein {
  from {transform: translateY(120px); opacity: 0.2;}
  to {transform: translateY(0px); opacity: 1.0;}
}

@media only screen and (min-width: 768px) {
  .start-top-left, .start-top-right {
    width: 50%;
  }

  @keyframes slidein {
    from {transform: translateX(60px) rotate(-10deg) translateY(120px); opacity: 0.2;}
    to {transform: translateX(60px) rotate(-10deg) translateY(0px); opacity: 1.0;}
  }

  .top-heading-cell {
    font-size: 56px;
    padding: 8px 32px;
    border-radius: 20px;
  }

  .top-heading-cell-bottom {
    transform: translateY(-4px);
  }

  .start-top-cells {
    flex-direction: row;
    align-items: center;
  }

  .start-top-left {
    align-items: flex-start;
    gap: 24px;
    margin: 48px 0 80px 0;
  }

  .top-heading {
    align-items: flex-start;
    gap: 0px;
  }

  .start-top-right img {
    width: 520px;
    transform-origin: center;
    transform: translateX(60px) rotate(-10deg);
  }

  .top-buttons {
    gap: 24px;
    margin-left: 36px;
  }
}

@media only screen and (min-width: 1152px) {
  .start-top-right img {
    width: 720px;
    transform-origin: center;
    transform: translate(60px, 0px) rotate(-10deg);
  }

  .top-heading-cell {
    font-size: 88px;
    padding: 8px 40px;
    border-radius: 24px;
  }

  .start-top-left {
    margin: 64px 0 96px 0;
  }

  .top-buttons {
    gap: 32px;
    margin-left: 40px;
  }
}

.start-projects-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  margin: 24px 0;
}

.start-projects-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
}

.project-cell {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.project-cell > img {
  width: 100%;
  height: 60vw;
  object-fit: cover;
  border-radius: 16px;
}

.project-cell-bottom {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  gap: 12px;
}

.project-cell-left {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.project-cell-bottom img {
  width: 28px;
  height: 28px;
}

.project-title {
  font-size: 18px;
  font-weight: 800;
  color: rgba(20, 20, 20, 1);
}

.project-description {
  font-size: 14px;
  font-weight: 600;
  color: rgba(102, 102, 115, 1);
}

.projects-grid {
  display: flex;
  flex-direction: column;
  gap: 40px;
}

@media only screen and (min-width: 768px) {
  .projects-grid {
    flex-direction: row;
    gap: 24px;
  }

  .project-cell > img {
    height: 240px;
  }

  .project-cell-bottom img {
    width: 24px;
    height: 24px;
  }
}

@media only screen and (min-width: 1152px) {
  .projects-grid {
    flex-direction: row;
    gap: 32px;
  }

  .project-cell > img {
    height: 360px;
  }

  .project-cell-bottom img {
    width: 32px;
    height: 32px;
  }

  .start-projects-header {
    margin-top: 24px;
  }

  .project-title {
    font-size: 20px;
  }

  .project-description {
    font-size: 16px;
  }
}

.contact-form {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 40px 0;
  gap: 20px;
}

.form-line {
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 16px;
}

.textfield {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
  font-weight: 800;
  font-size: 13px;
}

.contact-form input {
  box-sizing: border-box;
  width: 100%;
  height: 48px;
  background: rgba(130,132,138,0.16);
  border: none;
  color: black;
  border-radius: 12px;
  padding: 0 16px;
  font-size: 16px;
}

.contact-form input:focus {
  outline: none;
}

.contact-form textarea {
  box-sizing: border-box;
  width: 100%;
  background: rgba(130,132,138,0.16);
  border: none;
  color: black;
  border-radius: 12px;
  padding: 12px 16px;
  font-size: 16px;
  line-height: 160%;
  min-width: 100%;
  max-width: 100%;
  min-height: 200px;
  max-height: 480px;
  margin-bottom: 12px;
}

.contact-form textarea:focus {
  outline: none;
}

.primary-btn-fill {
  font-size: 16px;
  height: 48px;
  background-color: rgba(20, 20, 20, 1);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 28px;
  color: white;
  padding: 0 32px;
  font-weight: 800;
  margin: 0 auto;
  cursor: pointer;
}

@media only screen and (min-width: 768px) {
  .form-line {
    gap: 20px;
  }
}

@media only screen and (min-width: 1152px) {
  .contact-form input {
    height: 56px;
  }

  .textfield {
    font-size: 14px;
  }

  .contact-form {
    gap: 24px;
  }

  .primary-btn-fill {
    height: 56px;
    font-size: 18px;
  }
}

.footer {
  height: 64px;
  background-color: rgba(20, 20, 20, 1);
}

.success-message {
  font-size: 16px;
  font-weight: 700;
  color: rgb(11, 227, 159);
  transform: translateY(-8px);
}

.error-message {
  font-size: 16px;
  font-weight: 700;
  color: rgb(234, 37, 74);
  transform: translateY(-8px);
}